<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <v-container>
        <v-btn @click="products">Products</v-btn>
        <v-btn>Stocks</v-btn>
        <v-btn @click="ingredients">Ingredients</v-btn>
        <v-btn @click="dashboard">Dashboard</v-btn>
        <v-btn @click="logout">Logout</v-btn>

        <v-data-table :headers="headers" :items="stocks" :loading="loading" class="elevation-1">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Stocks</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                </v-toolbar>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import apiClient from '../axios';

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Stocks',
    data() {
        return {
            loading: true,
            stocks: [],
            headers: [
                { text: 'Ingredient Name', value: 'stock_ingredient' },
                { text: 'Unit Label', value: 'unit_label' },
                { text: 'Availability Label', value: 'availability_label' },
                { text: 'Visibility', value: 'visibility' },
                { text: 'Category Label', value: 'category_label' },

            ],
        };
    },
    mounted() {
        this.fetchStocks();
    },
    methods: {
        async fetchStocks() {
            try {
                const response = await apiClient.get('https://cct-backend.kentillation.com/api/stocks', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('auth_token')}`
                    }
                });
                this.stocks = response.data;
            } catch (error) {
                console.error('Error fetching stocks:', error);
            } finally {
                this.loading = false;
            }
        },
        async products() {
            try {
                this.$router.push('/products');
            } catch (error) {
                console.error('Error in accessing products:', error);
            }
        },
        async ingredients() {
            try {
                this.$router.push('/ingredients');
            } catch (error) {
                console.error('Error in accessing ingredients:', error);
            }
        },
        async dashboard() {
            try {
                this.$router.push('/dashboard');
            } catch (error) {
                console.error('Error in accessing dashboard:', error);
            }
        },
        async logout() {
            try {
                await apiClient.post('/logout', null, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('auth_token')}`
                    }
                });
                localStorage.removeItem('auth_token');
                this.$router.push('/');
            } catch (error) {
                console.error('Logout error:', error);
            }
        }
    }
};
</script>

<style scoped>
.v-data-table {
    max-width: 100%;
}
</style>