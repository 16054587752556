<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <v-container>
        <h1>Dashboard</h1>
        <v-btn @click="products">Products</v-btn>
        <v-btn @click="stocks">Stocks</v-btn>
        <v-btn @click="ingredients">Ingredients</v-btn>
        <v-btn @click="logout">Logout</v-btn>
    </v-container>
</template>

<script>
import apiClient from '../axios';

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Dashboard',
    methods: {
        async products() {
            try {
                this.$router.push('/products');
            } catch (error) {
                console.error('Error in accessing products:', error);
            }
        },
        async stocks() {
            try {
                this.$router.push('/stocks');
            } catch (error) {
                console.error('Error in accessing stocks:', error);
            }
        },
        async ingredients() {
            try {
                this.$router.push('/ingredients');
            } catch (error) {
                console.error('Error in accessing ingredients:', error);
            }
        },
        async logout() {
            try {
                await apiClient.post('/logout', null, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('auth_token')}`
                    }
                });
                localStorage.removeItem('auth_token');
                this.$router.push('/');
            } catch (error) {
                console.error('Logout error:', error);
            }
        }
    }
};
</script>