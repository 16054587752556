<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <v-container>
        <v-btn @click="products">Products</v-btn>
        <v-btn @click="stocks">Stocks</v-btn>
        <v-btn>Ingredients</v-btn>
        <v-btn @click="dashboard">Dashboard</v-btn>
        <v-btn @click="logout">Logout</v-btn>

        <v-data-table :headers="headers" :items="ingredients" :loading="loading" class="elevation-1">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Ingredients</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                </v-toolbar>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import apiClient from '../axios';

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Ingredients',
    data() {
        return {
            loading: true,
            ingredients: [],
            headers: [
                { text: 'Product Name', value: 'product_name' },
                { text: 'Temperature Label', value: 'temp_label' },
                { text: 'Size Label', value: 'size_label' },
                { text: 'Category Label', value: 'category_label' },
                { text: 'Availability Label', value: 'availability_label' },
                { text: 'Visibility', value: 'visibility' },
            ],
        };
    },
    mounted() {
        this.fetchIngredients();
    },
    methods: {
        async fetchIngredients() {
            try {
                const response = await apiClient.get('https://cct-backend.kentillation.com/api/ingredients', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('auth_token')}`
                    }
                });
                this.ingredients = response.data;
            } catch (error) {
                console.error('Error fetching ingredients:', error);
            } finally {
                this.loading = false;
            }
        },
        async products() {
            try {
                this.$router.push('/products');
            } catch (error) {
                console.error('Error in accessing products:', error);
            }
        },
        async stocks() {
            try {
                this.$router.push('/stocks');
            } catch (error) {
                console.error('Error in accessing stocks:', error);
            }
        },
        async dashboard() {
            try {
                this.$router.push('/dashboard');
            } catch (error) {
                console.error('Error in accessing products.:', error);
            }
        },
        async logout() {
            try {
                await apiClient.post('/logout', null, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('auth_token')}`
                    }
                });
                localStorage.removeItem('auth_token');
                this.$router.push('/');
            } catch (error) {
                console.error('Logout error:', error);
            }
        }
    }
};
</script>

<style scoped>
.v-data-table {
    max-width: 100%;
}
</style>