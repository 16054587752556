import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';
import { createVuetify } from 'vuetify';

const vuetify = createVuetify({
  theme: {
    defaultTheme: 'dark', // Set the default theme to dark
    themes: {
      dark: {
        dark: true,
      },
    },
  },
});

export default vuetify;
