import { createRouter, createWebHistory } from 'vue-router';
import Login from '../components/Login.vue';
import Register from '../components/Register.vue';
import Dashboard from '../components/Dashboard.vue';
import Products from '../components/Products.vue';
import Stocks from '../components/Stocks.vue';
import Ingredients from '../components/Ingredients.vue';

const routes = [
    { path: '/', name: 'Login', component: Login },
    { path: '/register', name: 'Register', component: Register },
    { path: '/dashboard', name: 'Dashboard', component: Dashboard, meta: { requiresAuth: true } },
    { path: '/products', name: 'Products', component: Products, meta: { requiresAuth: true } },
    { path: '/stocks', name: 'Stocks', component: Stocks, meta: { requiresAuth: true } },
    { path: '/ingredients', name: 'Ingredients', component: Ingredients, meta: { requiresAuth: true } },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    const loggedIn = localStorage.getItem('auth_token');

    if (to.matched.some(record => record.meta.requiresAuth) && !loggedIn) {
        next('/');
    } else {
        next();
    }
});

export default router;
